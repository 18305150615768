<template>
    <div class="printFe">

    </div>
</template>
<script>
import "@/common/js/jquery-1.8.0.min.js";
import "@/common/js/Print.js";
import "@/common/js/JsBarcode.js";
import hhPrint from "@/common/js/hhPrint.js";
import { v4 as uuidv4 } from "uuid";
import request from "@/common/js/request.js";
import moment from "moment";

export default {
    name: "info",
    data() {
        return {
            mould: '',
            curInfo: {}
        }
    },
    created() {
        if(this.$route.query.id){
            this.init();
        }
      
    },
    methods: {
        init() {
            Promise.all([this.$api.getMould1(), this.$api.byOrderGetInfo({
                order_sn: this.$route.query.id
            })]).then(res => {
                const num = res[1].data.tooth.split(',').length
                this.curInfo = Object.assign({}, res[1].data, { newTime: moment().format("YYYY-MM-DD"), num })
                const info = {
                    order_sn: this.curInfo.order_sn,
                    receive_date: this.curInfo.receive_date,
                    user_name: this.curInfo.user ? this.curInfo.user.name : '',
                    organization: this.curInfo.user ? this.curInfo.user.organization : '',
                    habits: this.curInfo.user ? this.curInfo.user.habits : '',
                    name: this.curInfo.name,
                    doctor: this.curInfo.doctor ? this.curInfo.doctor.title : '',
                    hospital: this.curInfo.hospital ? this.curInfo.hospital.title : '',
                    product: this.curInfo.ptitle ? this.curInfo.ptitle.title : '',
                    num: this.curInfo.num,
                    tooth: this.curInfo.tooth,
                    color: this.curInfo.color,
                    design_req: this.curInfo.design_req,
                    amount: this.curInfo.product_price,
                    block_code: this.curInfo.block_code,
                    remark: this.curInfo.remark,
                    area_name: this.curInfo.user ? this.curInfo.user.area_name : '',
                    user_type_name: this.curInfo.user ? this.curInfo.user.user_type_name : '',
                    newTime: this.curInfo.newTime,
                    attribute: this.curInfo.attribute,
                }
                this.$api.huipeng({
                    // sn: "960815108",
                    sn: "960812634",
                    content: hhPrint.printFE(res[0].data.info.content_json, [info]),
                })

            })
        },
    },
};
</script>
  
<style lang="scss">
.green {
    .el-input__inner {
        color: #2d8cf0 !important;
    }
}

.print {
    .card {
        .input-box {
            display: flex;
        }

        .top {
            .el-input__inner {
                width: 260px;
                margin-right: 10px;
            }

            .el-input__suffix {
                right: 10px;
            }

            .tips {
                font-size: 12px;
                color: red;
            }
        }

        .big-box {
            .el-input__inner {
                width: 280px;
                margin-right: 10px;
            }

            .el-date-editor .el-range-separator {
                padding: 0;
            }
        }

        .small-box {
            .el-input__inner {
                width: 130px;
                margin-right: 10px;
            }
        }

        .bottom {
            .el-pager {
                .number {
                    border: 1px solid #ebebeb;
                    margin-right: 4px;

                    &:hover {
                        color: #2d8cf0;
                    }

                    &.active {
                        background: #2d8cf0;
                        border: 1px solid #2d8cf0;
                        color: #ffffff;
                    }
                }
            }
        }

        .el-table th {
            background: #eef4f1;
            color: #333333;
        }
    }

    .add-form {
        .el-form-item {
            margin-bottom: 22px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .el-form-item--medium .el-form-item__content {
            margin-left: 0 !important;
        }

        .el-input__inner {
            width: 420px;
        }

        .fj-box {
            display: flex;
            flex-wrap: wrap;

            .fj-item {
                line-height: 110px;
                margin-right: 15px;
                padding: 0 10px;
                position: relative;

                .del {
                    position: absolute;
                    width: 26px;
                    height: 16px;
                    top: 30px;
                    right: -10px;
                    object-fit: contain;
                    cursor: pointer;
                }
            }
        }

        .upload-btn {
            width: 110px;
            height: 110px;
            background: #f1f9f5;
            border: 1px dashed #9a9c9b;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            line-height: 1.3;

            .icon {
                width: 26px;
                height: 26px;
                margin-bottom: 12px;
            }
        }

        .y-wei {
            width: 420px;
            display: flex;
            flex-wrap: wrap;
            position: relative;

            .box {
                display: flex;
                padding-right: 8px;
                border-bottom: 1px dashed #979797;
                border-right: 1px dashed #979797;
                padding-bottom: 10px;

                &:nth-child(2n) {
                    padding-right: 0;
                    border-right: none;
                    padding-left: 10px;
                }

                &:nth-child(3),
                &:nth-child(4) {
                    border-bottom: none;
                    padding-bottom: 0;
                    padding-top: 10px;
                }

                .item {
                    width: 23px;
                    line-height: 27px;
                    background: #eef4f1;
                    border-radius: 5px;
                    margin-right: 2px;
                    cursor: pointer;
                    text-align: center;
                    font-size: 16px;
                    color: #333333;

                    &.active {
                        background: #2d8cf0;
                        color: #fff;
                    }
                }
            }

            .select-top-all {
                position: absolute;
                right: -60px;
                top: -2px;
                color: #333;
            }

            .select-bottom-all {
                position: absolute;
                right: -60px;
                bottom: -2px;
                color: #333;
            }
        }
    }

    .el-button--primary {
        background: #2d8cf0 !important;
        border-color: #2d8cf0 !important;
    }
}
</style>
<style lang="scss" scoped>
.card-demo {
    width: 324px;
    height: 207px;
    background-color: pink;
    overflow: hidden;
    position: relative;

    .shu {
        position: absolute;
        height: 207px;
        width: 9px;
        border-right: 1px solid #000;
    }

    .hen {
        position: absolute;
        height: 9px;
        width: 324px;
        border-bottom: 1px solid #000;
    }
}

.add-form {
    ::v-deep .el-radio {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.print {
    width: 1150px;
    margin: 20px auto 0;

    .title {
        font-size: 30px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 30px 20px;
        border-radius: 8px;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);

        .select-label {
            font-size: 20px;
            font-weight: bold;
            margin-left: 40px;
        }

        .getPrint-btn {
            margin-left: 15px;
        }
    }

    .card {
        background: #ffffff;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
        border-radius: 5px;
        padding: 30px 30px 40px;
        position: relative;
        z-index: 1;

        .top {
            &.mb30 {
                margin-bottom: 20px;
            }

            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .search-btn {
                width: 90px;
                line-height: 36px;
                background: #2d8cf0;
                border-radius: 5px;
                text-align: center;
                font-size: 14px;
                color: #ffffff;
                // margin-right: 30px;
                cursor: pointer;
            }

            .add-btn {
                width: 126px;
                height: 36px;
                background: #2d8bf010;
                border: 1px solid #2d8cf0;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #2d8cf0;
                cursor: pointer;
                margin-right: 10px;

                .img {
                    margin-right: 6px;
                }
            }
        }

        .table {
            margin-bottom: 35px;

            .y-wei {
                width: 320px;
                display: flex;
                flex-wrap: wrap;

                .box {
                    display: flex;
                    padding-right: 8px;
                    border-bottom: 1px dashed #979797;
                    border-right: 1px dashed #979797;
                    padding-bottom: 10px;
                    width: 160px;
                    height: 32px;

                    &:nth-child(2n) {
                        padding-right: 0;
                        border-right: none;
                        padding-left: 10px;
                    }

                    &:nth-child(2n + 1) {
                        justify-content: flex-end;
                    }

                    &:nth-child(3),
                    &:nth-child(4) {
                        border-bottom: none;
                        padding-bottom: 0;
                        padding-top: 10px;
                    }

                    .item {
                        display: none;
                        width: 16px;
                        line-height: 20px;
                        // background: #eef4f1;
                        border-radius: 5px;
                        margin-right: 2px;
                        cursor: pointer;
                        text-align: center;
                        font-size: 14px;
                        color: #333333;

                        &.active {
                            display: block;
                            // background: #2D8CF0;
                            // color: #fff;
                        }
                    }
                }
            }

            .file-box {
                display: flex;
                flex-wrap: wrap;

                .file-item {
                    line-height: 1.5;
                    margin-right: 8px;
                }
            }
        }

        .bottom {
            display: flex;
            justify-content: center;
        }
    }
}
</style>